'use strict';

angular.module("loginApp", ["ngRoute", "ngResource", "pascalprecht.translate", "ui-notification", "ngSanitize", "ui.bootstrap.modal",
    "ui.bootstrap.tpls", "ui.router", "common"])
    .config(['$translateProvider',
        function ($translateProvider) {
            var preferredLanguage = (navigator.languages ? navigator.languages[0] : navigator.language);
            $translateProvider
                .useSanitizeValueStrategy('sanitize')
                .useStaticFilesLoader({
                    prefix: '/i18n/',
                    suffix: '.json'
                })
                .preferredLanguage(preferredLanguage)
                .fallbackLanguage('en-US');
        }])
    .run(['$translate', function ($translate) {
        $translate.refresh();
    }]);
